import { AppStateContext } from "appStateProvider";
import { Config } from "config/config";
import { useIntl } from "hooks/useIntl";
import React, { ChangeEvent, ReactElement, useContext, useState } from "react";
import "./languageSelector.scss";

export const LanguageSelector = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const { msg } = useIntl();

  const [selectedOption, setSelectedOption] = useState(appContext.language);

  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    window.localStorage.setItem(Config.LOCALSTORAGE_APP__LANGUAGE, e.target.value);
    setSelectedOption(e.target.value);
    appContext.setLanguage(e.target.value);
  }

  return (
    <div className="language-selector">
      <label>{msg("global.language")}</label>
      <select className="select-css" value={selectedOption} onChange={onChangeHandler}>
        {Object.keys(window.availableLanguages).map((lang, index)=>
          <option key={index} value={lang}>
            {Object.values(window.availableLanguages)[index]}
          </option>
        )}
      </select>
    </div>
  );
}
