import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { AppStateContext } from "appStateProvider";
import { NavigationBar } from "components/common/navigationBar/navigationBar";
import { About } from "components/pages/about/about";
import { Home } from "components/pages/home/home";
import { PageNotFound } from "components/pages/pageNotFound/pageNotFound";
import { EmailSentPage } from "components/pages/passwordReset/emailSentPage";
import { ForgottenPasswordPage } from "components/pages/passwordReset/forgottenPasswordPage";
import { PasswordRestoredPage } from "components/pages/passwordReset/passwordRestoredPage";
import { ResetPasswordPage } from "components/pages/passwordReset/resetPasswordPage";
import { SubscriptionPage } from "components/pages/subscriptionPage/subscriptionPage";
import { TimeCalculator } from "components/pages/utilities/timeCalculator/timeCalculator";
import { Utilities } from "components/pages/utilities/utilities";
import { WelcomeNewUserPage } from "components/pages/welcomeNewUserPage/welcomeNewUserPage";
import { paypalOptions } from "config/paypal";
import { getTheme } from "config/theme";
import React, { ReactElement, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import "styles/global.scss";
import "./app.scss";
import {
  PATH__ABOUT,
  PATH__EMAIL_SENT,
  PATH__FORGOTTEN_PASSWORD,
  PATH__HOME,
  PATH__PASSWORD_RESTORED,
  PATH__RESET_PASSWORD,
  PATH__SUBSCRIPTION,
  PATH__TIME_CALCULATOR,
  PATH__UTILITIES,
  PATH__WELCOME_NEW_USER
} from "./config/paths";

const PageWrapper = styled.div`
  position: relative;

  .page {
    padding-top: 32px;
    @media (max-width: ${props => props.theme.bp.mobile}) {
      position: absolute;
      top: 48px;
      left: 0;
    }
  }
`;

export const App = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <ThemeProvider theme={getTheme(appContext.theme)}>
        <Router>
          <div className="app">
            <NavigationBar />
            <PageWrapper>
              <Switch>
                <Route exact path={PATH__HOME}>
                  <Home />
                </Route>
                <Route path={PATH__ABOUT}>
                  <About />
                </Route>
                <Route path={PATH__FORGOTTEN_PASSWORD}>
                  <ForgottenPasswordPage />
                </Route>
                <Route path={PATH__RESET_PASSWORD}>
                  <ResetPasswordPage />
                </Route>
                <Route path={PATH__PASSWORD_RESTORED}>
                  <PasswordRestoredPage />
                </Route>
                <Route path={PATH__EMAIL_SENT}>
                  <EmailSentPage />
                </Route>
                <Route path={PATH__WELCOME_NEW_USER}>
                  <WelcomeNewUserPage />
                </Route>
                <Route path={PATH__UTILITIES}>
                  <Utilities />
                </Route>
                <Route path={PATH__TIME_CALCULATOR}>
                  <TimeCalculator />
                </Route>
                <Route path={PATH__SUBSCRIPTION}>
                  <SubscriptionPage />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </PageWrapper>
          </div>
        </Router>
      </ThemeProvider>
    </PayPalScriptProvider>
  );
};
