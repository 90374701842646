import { isNil, uniqueId } from "lodash";
import { ReactElement, useEffect } from "react";
import styled from "styled-components";

type Props = {
  zIndex?: string;
  opacity?: string;
  className?: string;
  onClick?: () => void;
};

const BackgroundShaderDiv = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: ${props => (isNil(props.zIndex) ? 10 : props.zIndex)};
  pointer-events: none;

  &.visible {
    opacity: ${props => (isNil(props.opacity) ? "0" : props.opacity)};
    pointer-events: all;
  }
`;

export const BackgroundShader = (props: Props): ReactElement => {
  const id = uniqueId("background-shader_");

  const stopTouchEvents = (event: TouchEvent) => {
    event.preventDefault();
    event.stopImmediatePropagation();
  };

  const getElement = () => {
    const el = document.getElementById(id);
    if (isNil(el)) {
      console.log("PSL ~ file: backgroundShader.tsx ~ line 42 ~ getElement ~ bg element not found!");
      return undefined;
    }
    console.log("PSL ~ file: backgroundShader.tsx ~ line 42 ~ getElement ~ elementFound!", el);
    return el as HTMLElement;
  };

  useEffect(() => {
    const el = getElement();
    el?.addEventListener("touchmove", stopTouchEvents, { passive: false });

    return () => {
      const el = getElement();
      el?.removeEventListener("touchmove", stopTouchEvents);
    };
  }, []);

  return (
    <div id={id}>
      <BackgroundShaderDiv {...props} />
    </div>
  );
};
