import { isNil } from "lodash";
import React, { ReactElement, ReactNode } from "react";
import "./responsiveContainer.scss";

type Props = {
  children: ReactNode;
  className?: string;
};

export const ResponsiveContainer = (props: Props): ReactElement => {
  const { children, className } = props;
  return (
    <div className={"responsive-container" + (isNil(className) ? "" : " " + className)}>
      <div className="responsive-container__content">{children}</div>
    </div>
  );
};
