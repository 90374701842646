import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";
import { useLocation } from "react-router";

// Ideas: https://www.tikalk.com/javascript/building-uml-editor-javascript-part-2

export const PageNotFound = (): ReactElement => {
  const location = useLocation();
  const { msg } = useIntl();

  return (
    <Page className="page-not-found">
      <ResponsiveContainer>
        <h1>Oh nooooo!! Page not found</h1>
        <h2>{msg("global.pageNotFound")}</h2>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </ResponsiveContainer>
    </Page>
  );
};
