import { isNil } from "lodash";
import { Role, User } from "types/common";

export const hasRole = (user: User | undefined, role: Role): boolean => !isNil(user) && user.roles.includes(role);

export const hasSomeRole = (user: User | undefined, roles: Role[]): boolean =>
  !isNil(user) && roles.some(role => user.roles.includes(role));

export const hasEveryRole = (user: User | undefined, roles: Role[]): boolean =>
  !isNil(user) && roles.every(role => user.roles.includes(role));

export const isAdmin = (user: User | undefined): boolean => hasRole(user, Role.ADMIN);

export const isLogged = (user: User | undefined): boolean => !isNil(user);
