import axios from "axios";
import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Spinner } from "components/common/spinner";
import { Page } from "components/layout/page";
import { Config } from "config/config";
import { PATH__EMAIL_SENT } from "config/paths";
import { useIntl } from "hooks/useIntl";
import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import { useHistory } from "react-router";

export const ForgottenPasswordPage = (): ReactElement => {
  const { msg } = useIntl();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);

  const changePassword = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.SEND_RESET_PASSWORD_EMAIL_SERVICE_URL}/${email}`,
      method: "GET",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        console.log("changePassword -> res", res);
        setRequestInProgress(false);
        history.push(PATH__EMAIL_SENT);
      })
      .catch(error => {
        console.log("Request error response:", error.response);
        setRequestInProgress(false);
      });
  };

  const handleEmailNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!requestInProgress) {
      changePassword();
    }
  };
  return (
    <Page className="email-sent">
      <ResponsiveContainer>
        <h1>{msg("login.forgottenPassword")}</h1>
        <p>{msg("login.forgottenPasswordMessage")}</p>
        <p>{msg("login.forgottenPasswordNote")}</p>

        <div className="form-wrapper">
          <div className="body">
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                autoComplete="email"
                id="email"
                name="email"
                placeholder={msg("login.enterEmail")}
                value={email}
                minLength={6}
                maxLength={40}
                required
                tabIndex={2}
                onChange={handleEmailNameChange}
                spellCheck={false}
              />

              {requestInProgress ? (
                <div>
                  <Spinner />
                </div>
              ) : (
                <input type="submit" value={msg("login.sendEmail")} tabIndex={4} />
              )}
            </form>
          </div>
        </div>
      </ResponsiveContainer>
    </Page>
  );
};
