import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";
import "./subscriptionPage.scss";
import { SubscriptionPlanCard } from "./subscriptionPlanCard/subscriptionPlanCard";

export const SubscriptionPage = (): ReactElement => {
  const { msg } = useIntl();

  return (
    <Page className="subscription">
      <ResponsiveContainer>
        <div className="subscription__header">
          <h1>{msg("page.subscription.title")}</h1>
          <h3>{msg("page.subscription.subtitle")}</h3>
        </div>
        <div className="subscription__content">
          <SubscriptionPlanCard name={"basic"} price={0} includedSettings={["hola1", "hola2"]} />
          <SubscriptionPlanCard name={"standard"} price={7.99} includedSettings={["hola3", "hola4"]} />
          <SubscriptionPlanCard name={"premium"} price={9.99} includedSettings={["hola5", "hola6"]} />
        </div>

        {/* {!isLogged(user) ? (
          <LoginForm showAlternativeForm={true} />
        ) : (
          <>
            <h2>
              {msg("global.hello")} {user?.username}!
            </h2>
          </>
        )} */}
      </ResponsiveContainer>
    </Page>
  );
};
