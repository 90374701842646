import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";

export const PasswordRestoredPage = (): ReactElement => {
  const { msg } = useIntl();
  return (
    <Page className="password-restored">
      <ResponsiveContainer>
        <h1>{msg("login.passwordRestored")}</h1>
        <h1>{msg("login.emailSent")}</h1>
      </ResponsiveContainer>
    </Page>
  );
};
