import React, { ReactElement } from "react";
import styled from "styled-components";

const Button = styled.div`
  width: 2rem;
  height: 2rem;
  position: relative;
  cursor: pointer;
  z-index: 20;

  .hamburger {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 1;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: ${props => props.theme.fgColor1};
      border-radius: 3px;
      transform-origin: 4px 0;
      transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1),
        background-color 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.35s ease;
    }

    &.checked {
      .top {
        transform: rotate(45deg) translate(6px, -1px);
        background-color: ${props => props.theme.fgColor2};
      }

      .middle {
        opacity: 0;
        transform: scale(0.2, 0.2);
      }

      .bottom {
        transform: rotate(-45deg) translate(2px, 0);
        background-color: ${props => props.theme.fgColor2};
      }
    }
  }
`;

type Props = {
  checked: boolean;
  setChecked: (checked: boolean) => void;
};

export const HamburgerMenuButton = (props: Props): ReactElement => {
  const onClickHandler = () => {
    props.setChecked(!props.checked);
  };

  return (
    <Button {...props} onClick={onClickHandler}>
      <div className={props.checked ? "hamburger checked" : "hamburger"}>
        <span className="top"></span>
        <span className="middle"></span>
        <span className="bottom"></span>
      </div>
    </Button>
  );
};
