import { LoginForm } from "components/common/login/loginForm";
import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { Config } from "config/config";
import { useIntl } from "hooks/useIntl";
import { useUser } from "hooks/useUser";
import React, { ReactElement } from "react";
import { isLogged } from "utils/userUtils";

export const Home = (): ReactElement => {
  const { msg } = useIntl();
  const user = useUser();

  return (
    <Page className="homepage">
      <ResponsiveContainer>
        <h1>{msg("page.home.title")}</h1>

        {!isLogged(user) ? (
          <LoginForm showAlternativeForm={true} />
        ) : (
          <>
            <h2>
              {msg("global.hello")} {user?.username}!
            </h2>
            <p>App version: {Config.APP_VERSION}</p>
          </>
        )}
      </ResponsiveContainer>
    </Page>
  );
};
