import { AppStateContext } from "appStateProvider";
import userIcon from "assets/icons/user.svg";
import { isNil } from "lodash";
import React, { ReactElement, useContext } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.fgColor1};

  .user-image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.3rem;
    cursor: pointer;
    border: 1px solid ${props => props.theme.bgColor2};
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;

      &.unregistered {
        filter: grayscale();
      }
    }

    .border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 1.3rem;
      border: 1px solid ${props => props.theme.fgColor1};
      transition: all 0.3s ease-out;

      &:hover {
        border: 3px solid ${props => props.theme.fgColor1};
      }
    }
  }
`;

export const NavigationBarUserIcon = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  return isNil(appContext.user) ? (
    <StyledDiv className="user-info">
      <div className="user-image">
        <img className="unregistered" alt="user" src={userIcon} />
        <div className="border"></div>
      </div>
    </StyledDiv>
  ) : (
    <StyledDiv>
      <div className="user-image">
        <img alt="user" src={userIcon} />
        <div className="border"></div>
      </div>
    </StyledDiv>
  );
};
