import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";

export const About = (): ReactElement => {
  const { msg } = useIntl();

  return (
    <Page className="about">
      <ResponsiveContainer>
        <h1>{msg("page.about.title")}</h1>
      </ResponsiveContainer>
    </Page>
  );
};
