import { isNil } from "lodash";
import englishDictionary from "./en.json";
import spanishDictionary from "./es.json";
import galicianDictionary from "./gl.json";

export const getPreferredLanguage = (): string => {
  const availLang = Object.keys(window.availableLanguages);
  if (navigator.languages !== undefined) {
    for (let i = 0; i < navigator.languages.length; i++) {
      if (availLang.includes(navigator.languages[i])) {
        return navigator.languages[i];
      }
    }
    return availLang[0];
  } else {
    if (availLang.includes(navigator.language)) {
      return navigator.language;
    }
    return availLang[0];
  }
};

export const getI8nText = (lang: string, key: string): string => {
  const availLang = Object.keys(window.availableLanguages);

  let text = "%" + key + "%";
  if (!isNil(window.dictionary[availLang[0]][key])) text = window.dictionary[availLang[0]][key];
  if (!isNil(window.dictionary[lang][key])) text = window.dictionary[lang][key];

  return text;
};

window.availableLanguages = { en: "English", es: "Español", gl: "Galego" };
window.preferredLanguage = getPreferredLanguage();
window.dictionary = {};

//Get emojis from:
//https://getemoji.com/
//https://emojipedia.org/rocket/
window.dictionary["en"] = englishDictionary;
window.dictionary["es"] = spanishDictionary;
window.dictionary["gl"] = galicianDictionary;
