import { UserHasNoPermissionsPage } from "components/pages/userHasNoPermissionsPage/userHasNoPermissionsPage";
import { UserNotLoggedInPage } from "components/pages/userNotLoggedInPage/userNotLoggedInPage";
import { useUser } from "hooks/useUser";
import { isNil } from "lodash";
import React, { ReactElement } from "react";
import { Role } from "types/common";
import { hasSomeRole, isLogged } from "../../utils/userUtils";

type Props = {
  className?: string;
  //https://stackoverflow.com/a/55858755
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  forRoles?: Role[];
};

export const Page = (props: Props): ReactElement => {
  const { className, children, forRoles } = props;
  const user = useUser();

  const getValidPage = () => <div className={"page" + (isNil(className) ? "" : " " + className)}>{children}</div>;

  if (isNil(forRoles) || hasSomeRole(user, forRoles)) {
    return getValidPage();
  } else {
    if (!isLogged(user)) {
      return <UserNotLoggedInPage />;
    } else {
      return <UserHasNoPermissionsPage />;
    }
  }
};
