import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";

export const EmailSentPage = (): ReactElement => {
  const { msg } = useIntl();
  return (
    <Page className="email-sent">
      <ResponsiveContainer>
        <h1>{msg("login.emailSent")}</h1>
      </ResponsiveContainer>
    </Page>
  );
};
