import { PATH__ABOUT, PATH__HOME, PATH__SUBSCRIPTION, PATH__UTILITIES } from "config/paths";
import { useIntl } from "hooks/useIntl";
import { useUser } from "hooks/useUser";
import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { isLogged } from "utils/userUtils";
import { Drawer } from "../drawer";
import { HamburgerMenuButton } from "../hamburgerMenuButton";
import { LanguageSelector } from "../language/languageSelector";
import { LogOutButton } from "../login/logOutButton";
import { ResponsiveContainer } from "../responsiveContainer/responsiveContainer";
import { ThemeSelector } from "../theme/themeSelector";
import { Logo } from "./logo";
import "./navigationBar.scss";
import { NavigationBarItemWithMenu } from "./navigationBarItemWithMenu";
import { NavigationBarUserIcon } from "./navigationBarUserIcon";

const NavigationBarWrapper = styled.div`
  position: relative;
  @media (max-width: ${props => props.theme.bp.mobile}) {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .navigation-bar {
    background-color: ${props => props.theme.fgColor2};
  }

  .desktop {
    height: 100%;
    display: block;
    @media (max-width: ${props => props.theme.bp.mobile}) {
      display: none;
    }
  }

  .mobile {
    height: 100%;
    display: none;
    @media (max-width: ${props => props.theme.bp.mobile}) {
      display: block;
    }
  }
`;

export const NavigationBar = (): ReactElement => {
  const user = useUser();
  const { msg } = useIntl();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const hideDrawer = () => setDrawerVisible(false);

  const getNavContent = () => (
    <nav>
      <ul>
        <li>
          <Link to={PATH__HOME} onClick={hideDrawer}>
            {msg("path.home")}
          </Link>
        </li>
        {isLogged(user) && (
          <li>
            <Link to={PATH__UTILITIES} onClick={hideDrawer}>
              {msg("page.utilities.title")}
            </Link>
          </li>
        )}
        <li>
          <Link to={PATH__ABOUT} onClick={hideDrawer}>
            {msg("path.about")}
          </Link>
        </li>
        <li>
          <Link to={PATH__SUBSCRIPTION} onClick={hideDrawer}>
            {msg("path.subscription")}
          </Link>
        </li>
      </ul>
    </nav>
  );

  return (
    <NavigationBarWrapper>
      <div className="navigation-bar">
        <div className="desktop">
          <ResponsiveContainer>
            <div className="left">
              <Logo />
              {getNavContent()}
            </div>
            <div className="center"></div>
            <div className="right">
              <ThemeSelector />
              <LanguageSelector />
              <NavigationBarItemWithMenu
                item={<NavigationBarUserIcon />}
                menu={
                  <div className="user-settings-dropdown">
                    <div className="dropdown-section">
                      <div>Signed in as</div>
                      <div>{user?.username}</div>
                    </div>
                    <div className="dropdown-divider" />
                    <div className="dropdown-section">
                      <div>This is another message</div>
                    </div>
                    <div className="dropdown-section">
                      <LogOutButton />
                    </div>
                  </div>
                }
              />
            </div>
          </ResponsiveContainer>
        </div>

        <div className="mobile">
          <ResponsiveContainer>
            <div className="left">
              <HamburgerMenuButton checked={drawerVisible} setChecked={setDrawerVisible} />
            </div>
            <div className="center">
              <Logo />
            </div>
            <div className="right"></div>
            <Drawer visible={drawerVisible} setVisible={setDrawerVisible}>
              <div className="drawer-content">
                {getNavContent()}
                <ThemeSelector />
                <LanguageSelector />
                <LogOutButton />
              </div>
            </Drawer>
          </ResponsiveContainer>
        </div>
      </div>
    </NavigationBarWrapper>
  );
};
