import axios from "axios";
import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Spinner } from "components/common/spinner";
import { Page } from "components/layout/page";
import { Config } from "config/config";
import { PATH__PASSWORD_RESTORED } from "config/paths";
import { useIntl } from "hooks/useIntl";
import { isNil } from "lodash";
import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";

export const ResetPasswordPage = (): ReactElement => {
  const { msg } = useIntl();

  const getURLSearchParam = (searchParamName: string): string | undefined => {
    const searchParam = new URLSearchParams(window.location.search).get(searchParamName);
    if (isNil(searchParam)) {
      return undefined;
    } else {
      return searchParam;
    }
  };

  //Validate token request param
  const token = getURLSearchParam("token");
  // console.log("token", token);

  let md5, emailLetters, emailDomain, num;
  let tokenTemplateIsOK = true;

  if (!token) {
    tokenTemplateIsOK = false;
  } else {
    const tokenSplitted = token.split("|");

    if (tokenSplitted.length !== 4) {
      tokenTemplateIsOK = false;
    } else {
      md5 = tokenSplitted[0];
      emailLetters = tokenSplitted[1];
      emailDomain = tokenSplitted[2];
      num = tokenSplitted[3];
      // console.log("md5", md5)
      // console.log("emailLetters", emailLetters)
      // console.log("emailDomain", emailDomain)

      if (md5.length !== 32 || emailLetters.length !== 2 || emailDomain.length === 0 || num.length === 0) {
        tokenTemplateIsOK = false;
      }
    }
  }

  // console.log("tokenTemplateIsOK", tokenTemplateIsOK)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  // eslint-disable-next-line
  const [validToken, setValidToken] = useState(tokenTemplateIsOK);

  const changePassword = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.CHANGE_PASSWORD_SERVICE_URL}`,
      method: "POST",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email,
        password: password,
        token: token,
      },
    })
      .then(() => {
        setRequestInProgress(false);
        window.location.replace(PATH__PASSWORD_RESTORED);
      })
      .catch(error => {
        console.log("Request error response:", error.response);
        setEmailError(true);
        setRequestInProgress(false);
      });
  };

  const handleEmailNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (emailError) {
      setEmailError(false);
    }
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    changePassword();
  };
  return (
    <Page className="reset-password">
      <ResponsiveContainer>
        <h1>{msg("login.resetPassword")}</h1>

        {!validToken ? (
          <p>{msg("login.resetPasswordBadToken")}</p>
        ) : (
          <>
            <p>
              {`${msg("login.resetPasswordMessage")} `}
              {!isNil(emailLetters) && <span>{`${emailLetters[0]}***${emailLetters[1]}@${emailDomain}`}</span>}
            </p>
            <div className="form-wrapper">
              <div className="body">
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    // autoComplete="email"
                    id="email"
                    name="email"
                    placeholder={msg("login.enterEmail")}
                    value={email}
                    minLength={6}
                    maxLength={40}
                    required
                    tabIndex={2}
                    onChange={handleEmailNameChange}
                    spellCheck={false}
                    // error={`${emailError}`}
                  />
                  {!emailError ? "" : <div className="errorMessage">{msg("login.emailNotValid")}</div>}

                  <input
                    type="password"
                    // autoComplete="password"
                    id="password"
                    name="password"
                    placeholder={msg("login.enterNewPassword")}
                    value={password}
                    minLength={6}
                    maxLength={40}
                    required
                    tabIndex={3}
                    onChange={handlePasswordChange}
                  />
                  {requestInProgress ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    <input type="submit" value={msg("login.setNewPassword")} tabIndex={4} />
                  )}
                </form>
              </div>
            </div>
          </>
        )}
      </ResponsiveContainer>
    </Page>
  );
};
