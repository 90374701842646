import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import { isNil } from "lodash";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import "./timeCalculator.scss";

const WORK_HOURS_WEEK = 40;

enum Day {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THRUSDAY = "thursday",
  FRIDAY = "friday",
}

export const isInt = (val: string): boolean => {
  return !isNaN(Number(val)) && Number.isInteger(parseFloat(val));
};

export const TimeCalculator = (): ReactElement => {
  const [workingHours, setWorkingHours] = useState<string>(WORK_HOURS_WEEK.toString());
  const [monday, setMonday] = useState(window.localStorage.getItem(Day.MONDAY) ?? "");
  const [tuesday, setTuesday] = useState(window.localStorage.getItem(Day.TUESDAY) ?? "");
  const [wednesday, setWednesday] = useState(window.localStorage.getItem(Day.WEDNESDAY) ?? "");
  const [thursday, setThursday] = useState(window.localStorage.getItem(Day.THRUSDAY) ?? "");
  const [friday, setFriday] = useState(window.localStorage.getItem(Day.FRIDAY) ?? "");

  const [totalMinutesWorked, setTotalMinutesWorked] = useState<number | undefined>();

  const { msg } = useIntl();

  useEffect(() => {
    const minMonday = getMinutes(monday);
    const minTuesday = getMinutes(tuesday);
    const minWednesday = getMinutes(wednesday);
    const minThursday = getMinutes(thursday);
    const minFriday = getMinutes(friday);

    if (!isNil(minMonday) && !isNil(minTuesday) && !isNil(minWednesday) && !isNil(minThursday) && !isNil(minFriday)) {
      setTotalMinutesWorked(minMonday + minTuesday + minWednesday + minThursday + minFriday);
    } else {
      setTotalMinutesWorked(undefined);
    }
  }, [friday, monday, thursday, tuesday, wednesday]);

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    setter: (val: string) => void,
    localStorageName?: Day,
  ) => {
    setter(event.target.value);
    if (!isNil(localStorageName)) {
      window.localStorage.setItem(localStorageName, event.target.value);
    }
  };

  const onBlurHandler = (
    event: ChangeEvent<HTMLInputElement>,
    setter: (val: string) => void,
    localStorageName?: Day,
  ) => {
    if (isInt(event.target.value)) {
      const newVal = event.target.value + "h 00m";
      setter(newVal);
      if (!isNil(localStorageName)) {
        window.localStorage.setItem(localStorageName, newVal);
      }
    }
  };

  const getMinutes = (val: string): number | undefined => {
    if (val.trim().length === 0) {
      return 0;
    } else {
      const valArr = val.split(" ");
      if (
        valArr.length === 2 &&
        valArr[0].length > 1 &&
        valArr[1].length > 1 &&
        valArr[0][valArr[0].length - 1] === "h" &&
        valArr[1][valArr[1].length - 1] === "m"
      ) {
        const hours = valArr[0].substring(0, valArr[0].length - 1);
        const minutes = valArr[1].substring(0, valArr[1].length - 1);
        if (isInt(hours) && isInt(minutes)) {
          return Number(hours) * 60 + Number(minutes);
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
  };

  type Time = { negative: boolean; hours: number; minutes: number };

  const getRemainingTime = (totalMinutesWorked: number): Time => {
    let negative = false;
    const totalRemainingMinutes = Number(workingHours) * 60 - totalMinutesWorked;
    if (totalRemainingMinutes < 0) {
      negative = true;
    }
    const remainingMinutes = Math.abs(totalRemainingMinutes) % 60;
    const remainigHours = Math.floor(Math.abs(totalRemainingMinutes) / 60);

    return {
      negative: negative,
      hours: remainigHours,
      minutes: remainingMinutes,
    };
  };

  const convertRemainingTimeToString = (time: Time) =>
    (time.negative ? "-" : "") + time.hours + "h " + time.minutes + "m";

  return (
    <Page className="time-calculator">
      <ResponsiveContainer>
        <h1>{msg("page.timeCalculator.title")}</h1>
        <form>
          <div className="day-input">
            <label htmlFor="workingHours">working hours</label>
            <input
              name="workingHours"
              type="text"
              value={workingHours}
              onChange={event => onChangeHandler(event, setWorkingHours)}
            />
          </div>
          <br />
          <div className="day-input">
            <label htmlFor={Day.MONDAY}>{Day.MONDAY}</label>
            <input
              name={Day.MONDAY}
              type="text"
              value={monday}
              onChange={event => onChangeHandler(event, setMonday, Day.MONDAY)}
              onBlur={event => onBlurHandler(event, setMonday, Day.MONDAY)}
            />
          </div>

          <div className="day-input">
            <label htmlFor={Day.TUESDAY}>{Day.TUESDAY}</label>
            <input
              name={Day.TUESDAY}
              type="text"
              value={tuesday}
              onChange={event => onChangeHandler(event, setTuesday, Day.TUESDAY)}
              onBlur={event => onBlurHandler(event, setTuesday, Day.TUESDAY)}
            />
          </div>

          <div className="day-input">
            <label htmlFor={Day.WEDNESDAY}>{Day.WEDNESDAY}</label>
            <input
              name={Day.WEDNESDAY}
              type="text"
              value={wednesday}
              onChange={event => onChangeHandler(event, setWednesday, Day.WEDNESDAY)}
              onBlur={event => onBlurHandler(event, setWednesday, Day.WEDNESDAY)}
            />
          </div>

          <div className="day-input">
            <label htmlFor={Day.THRUSDAY}>{Day.THRUSDAY}</label>
            <input
              name={Day.THRUSDAY}
              type="text"
              value={thursday}
              onChange={event => onChangeHandler(event, setThursday, Day.THRUSDAY)}
              onBlur={event => onBlurHandler(event, setThursday, Day.THRUSDAY)}
            />
          </div>

          <div className="day-input">
            <label htmlFor={Day.FRIDAY}>{Day.FRIDAY}</label>
            <input
              name={Day.FRIDAY}
              type="text"
              value={friday}
              onChange={event => onChangeHandler(event, setFriday, Day.FRIDAY)}
              onBlur={event => onBlurHandler(event, setFriday, Day.FRIDAY)}
            />
          </div>
        </form>
        <br />
        <div>
          {isNil(totalMinutesWorked) ? (
            <>Error</>
          ) : (
            <>
              <div className="total-worked">
                Total time worked: <span className="hours">{Math.floor(totalMinutesWorked / 60)}h </span>
                <span className="minutes">{totalMinutesWorked % 60}m</span>
              </div>
              {!isNil(totalMinutesWorked) && (
                <div className="total-remaining">
                  <span>Total time remaining: </span>
                  <span>{convertRemainingTimeToString(getRemainingTime(totalMinutesWorked))}</span>
                  {getRemainingTime(totalMinutesWorked).negative ? (
                    <span> (Te has pasado)</span>
                  ) : (
                    <span> (Te falta)</span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </ResponsiveContainer>
    </Page>
  );
};
