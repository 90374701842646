export const PATH__HOME = "/";
export const PATH__ABOUT = "/about";
export const PATH__FORGOTTEN_PASSWORD = "/forgotten-password";
export const PATH__RESET_PASSWORD = "/reset-password";
export const PATH__PASSWORD_RESTORED = "/password-restored";
export const PATH__EMAIL_SENT = "/email-sent";
export const PATH__WELCOME_NEW_USER = "/welcome";
export const PATH__UTILITIES = "/utilities";
export const PATH__TIME_CALCULATOR = "/time-calculator";
export const PATH__SUBSCRIPTION = "/subscribe";
