import {
  CreateOrderActions,
  OnApproveActions,
  OnApproveData,
  OnCancelledActions,
  UnknownObject
} from "@paypal/paypal-js/types/components/buttons";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";
import "./subscriptionPlanCard.scss";
type Props = {
  name: string;
  price: number;
  includedSettings: string[];
};

export const SubscriptionPlanCard = (props: Props): ReactElement => {
  const { name, price, includedSettings } = props;
  const { msg } = useIntl();

  const getOrderObject = () => {
    return {
      purchase_units: [
        {
          amount: {
            value: price.toString(),
          },
        },
      ],
    }
  }

  const createOrderHandler = (data: UnknownObject, actions: CreateOrderActions): Promise<string> => {
    console.log("PSL ~ file: home.tsx ~ line 23 ~ Home ~ data", data);
    console.log("PSL ~ file: home.tsx ~ line 23 ~ Home ~ actions", actions);
    return actions.order.create(getOrderObject());
  };

  const onApproveHandler = (data: OnApproveData, actions: OnApproveActions): Promise<void> => {
    console.log("PSL ~ file: home.tsx ~ line 17 ~ Home ~ data", data);
    console.log("PSL ~ file: home.tsx ~ line 17 ~ Home ~ actions", actions);
    return actions.order.capture().then(function (details) {
      // This function shows a transaction success message to your buyer.
      alert("Transaction completed by " + details.payer.name.given_name);
    });
  };

  const onCancelHandler = (data: UnknownObject, actions: OnCancelledActions) => {
    console.log("PSL ~ file: home.tsx ~ line 37 ~ onCancelHandler ~ data", data);
    console.log("PSL ~ file: home.tsx ~ line 37 ~ onCancelHandler ~ actions", actions);
    alert("Cancelled!");
  };

  const onErrorHandler = (err: UnknownObject) => {
    console.log("PSL ~ file: home.tsx ~ line 43 ~ onErrorHandler ~ err", err);
    alert("Paypal error!");
  };

  return (
    <div className="subscription-plan-card">
      <div className="subscription-plan-card__title">{name}</div>
      <div className="subscription-plan-card__price">
        {price === 0 ? (
          <span className="price">{msg("subscription.free")}</span>
        ) : (
          <>
            <span className="price">${price}</span>
            <span className="per-month">{msg("subscription.perYear")}</span>
          </>
        )}
      </div>
      <div className="subscription-plan-card__settings">
        {includedSettings.map(setting => (
          <div key={setting}>{setting}</div>
        ))}
      </div>
      <div className="subscription-plan-card__button">
      {price === 0 ? (
        <div>Register to get this plan!</div>
      ):(
        <PayPalButtons
          style={{ layout: "horizontal" }}
          createOrder={createOrderHandler}
          onApprove={onApproveHandler}
          onCancel={onCancelHandler}
          onError={onErrorHandler}
        />
      )}
      </div>
    </div>
  );
};
