//https://classic.yarnpkg.com/en/docs/cli/version
import { isNil } from "lodash";
import { Environment } from "types/common";
import appInfo from "../../package.json";

if (isNil(window.environment)) {
  window.environment = Environment.PRODUCTION;
  if (window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") {
    window.environment = Environment.DEVELOPMENT;
  }
}
let API_HOST = "https://api.guitarnotebook.com";
if (window.environment === Environment.DEVELOPMENT) {
  API_HOST = "http://localhost:8080";
}
if (window.environment === Environment.DEVELOPMENT) {
  console.log("window.location: ", window.location);
  console.log("Environment: ", window.environment);
  console.log("API_HOST: ", API_HOST);
}

export const Config = {
  APP_VERSION: appInfo.version,

  SIGNUP_SERVICE_URL: API_HOST + "/api/auth/signup",
  SIGNIN_SERVICE_URL: API_HOST + "/api/auth/signin",
  SEND_RESET_PASSWORD_EMAIL_SERVICE_URL: API_HOST + "/api/user/reset-password",
  CHANGE_PASSWORD_SERVICE_URL: API_HOST + "/api/user/change-password",

  REQUEST_TIMEOUT: 20000,

  LOCALSTORAGE_USER__JWT_DATA: "gnbo__user-jwt-data",
  LOCALSTORAGE_USER__AUTH_TOKEN: "gnbo__user-auth-token",
  LOCALSTORAGE_APP__LANGUAGE: "gnbo__app-language",
  LOCALSTORAGE_APP__THEME: "gnbo__app-theme",

  API_RESPONSE_MESSAGE: ["User registered successfully", "Username is already taken", "Email is already in use"],
};
