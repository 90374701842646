import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";

export const UserHasNoPermissionsPage = (): ReactElement => {
  const { msg } = useIntl();

  return (
    <Page className="user-has-no-permissions">
      <ResponsiveContainer>
        <h1>{msg("auth.userHasNoPermissions")}</h1>
      </ResponsiveContainer>
    </Page>
  );
};
