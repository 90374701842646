import React, { ReactElement } from "react";
import { App } from "./app";
import { AppStateProvider } from "./appStateProvider";

export const AppWithContext = (): ReactElement => {
  return (
    <AppStateProvider>
      <App />
    </AppStateProvider>
  );
};
