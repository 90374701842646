import { LoginForm } from "components/common/login/loginForm";
import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";

export const UserNotLoggedInPage = (): ReactElement => {
  const { msg } = useIntl();

  return (
    <Page className="user-not-logged-in">
      <ResponsiveContainer>
        <h1>{msg("auth.loggedUserRequired")}</h1>
        <LoginForm />
      </ResponsiveContainer>
    </Page>
  );
};
