import { AppStateContext } from "appStateProvider";
import { Config } from "config/config";
import { PATH__HOME } from "config/paths";
import { useIntl } from "hooks/useIntl";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useEffect } from "react";
import { useHistory } from "react-router";

type Props = {
  onClick?: () => void;
};

export const LogOutButton = (props: Props): ReactElement => {
  const { onClick } = props;
  const appContext = useContext(AppStateContext);
  const { msg } = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (isNil(appContext.user)) {
      history.push(PATH__HOME);
    }
  }, [appContext.user]);

  const handleClickCloseButton = () => {
    sessionStorage.removeItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN);
    sessionStorage.removeItem(Config.LOCALSTORAGE_USER__JWT_DATA);
    appContext.setUser(undefined);
    if (!isNil(onClick)) {
      onClick();
    }
  };

  return (
    <div>
      <button className="close-button" onClick={handleClickCloseButton}>
        {msg("login.logOut")}
      </button>
    </div>
  );
};
