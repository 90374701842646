import { getPreferredLanguage } from "components/common/language/language";
import { Config } from "config/config";
import { Theme } from "config/theme";
import { isNil } from "lodash";
import React, { createContext, ReactElement, useEffect, useState } from "react";
import { User } from "types/common";

function getStoredValueOrDefault<T>(localStorageSettingName: string, defaultValue: T) {
  const storedValue = window.localStorage.getItem(localStorageSettingName);
  // eslint-disable-next-line no-null/no-null
  if (storedValue === null) {
    return defaultValue;
  } else {
    return storedValue as unknown as T;
  }
}

const getStoredUser = () => {
  const storedUser = window.sessionStorage.getItem(Config.LOCALSTORAGE_USER__JWT_DATA);
  if (isNil(storedUser)) {
    return undefined;
  }
  const parsedStoredUser: User | undefined = JSON.parse(storedUser);
  return parsedStoredUser;
};

////////////////////////
// Context definition //
////////////////////////
type ContextProps = {
  language: string;
  setLanguage: (val: string) => void;
  theme: Theme;
  setTheme: (val: Theme) => void;
  user: User | undefined;
  setUser: (val: User | undefined) => void;
};

const contextInitValues: ContextProps = {
  language: "",
  setLanguage: () => {},
  theme: Theme.DARK,
  setTheme: () => {},
  user: undefined,
  setUser: () => {},
};

export const AppStateContext = createContext<ContextProps>(contextInitValues);


////////////////////////////////
// AppStateProvider Component //
////////////////////////////////
type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
};

export const AppStateProvider = (props: Props): ReactElement => {
  const { children } = props;

  const storedUser = getStoredUser();
  const storedLang = getStoredValueOrDefault<string>(Config.LOCALSTORAGE_APP__LANGUAGE, getPreferredLanguage());
  const storedTheme = getStoredValueOrDefault<string>(Config.LOCALSTORAGE_APP__THEME, Theme.DARK);
  const [user, setUser] = useState(storedUser);
  const [language, setLanguage] = useState(storedLang);
  const [theme, setTheme] = useState(storedTheme as Theme);

  const state = {
    language: language,
    setLanguage: setLanguage,
    theme: theme,
    setTheme: setTheme,

    user: user,
    setUser: setUser,
  };

  useEffect(() => {
    console.log("PSL ~ file: AppStateProvider.tsx ~ AppStateProvider ~ state", state);
  }, [state]);

  return <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>;
};
