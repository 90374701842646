import { SignInForm } from "components/common/login/signInForm";
import { SignUpForm } from "components/common/login/signUpForm";
import { PATH__HOME, PATH__WELCOME_NEW_USER } from "config/paths";
import React, { ReactElement, useState } from "react";
import { SignForm } from "utils/authUtils";

type Props = {
  defaultForm?: SignForm;
  showAlternativeForm?: boolean;
};

export const LoginForm = (props: Props): ReactElement => {
  const { defaultForm, showAlternativeForm } = props;
  const [selectedSignForm, setSelectedSignForm] = useState<SignForm>(defaultForm ?? SignForm.SIGN_IN);

  const getSignForm = () => {
    switch (selectedSignForm) {
      case SignForm.SIGN_IN:
        return (
          <SignInForm
            onSwitchToSignUp={() => setSelectedSignForm(SignForm.SIGN_UP)}
            showAlternativeForm={showAlternativeForm}
            onSuccessRedirectTo={PATH__HOME}
          />
        );
      case SignForm.SIGN_UP:
        return (
          <SignUpForm
            onSwitchToSignIn={() => setSelectedSignForm(SignForm.SIGN_IN)}
            showAlternativeForm={showAlternativeForm}
            onSuccessRedirectTo={PATH__WELCOME_NEW_USER}
          />
        );
      default:
        return <></>;
    }
  };

  return getSignForm();
};
