import { BackgroundShader } from "components/common/backgroundShader";
import React, { ReactElement } from "react";
import "./navigationBarItemMenu.scss";

type Props = {
  visible: boolean;
  setVisible: (val: boolean) => void;
  children: ReactElement;
};

export const NavigationBarItemMenu = (props: Props): ReactElement => {
  const { visible, setVisible, children } = props;
  // const user = useUser();
  // const { msg } = useIntl();

  const hideMenu = () => setVisible(false);

  return (
    <>
      <BackgroundShader className={visible ? "visible" : ""} onClick={hideMenu} />
      <div className="navigation-bar-item-menu">
        <div className="menu-bg">
          <div className={"menu" + (visible ? " visible" : "")}>
            <div className="menu__content">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
