import { SignInForm } from "components/common/login/signInForm";
import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { PATH__HOME } from "config/paths";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";
import { useHistory } from "react-router";

export const WelcomeNewUserPage = (): ReactElement => {
  const { msg } = useIntl();
  const history = useHistory();
  const username = (history.location.state as { username: string }).username;
  return (
    <Page className="welcome-new-user">
      <ResponsiveContainer>
        <h1>
          {msg("login.hello")} {username}, {msg("login.welcomeNewUser")}
        </h1>
        <SignInForm onSuccessRedirectTo={PATH__HOME} showAlternativeForm={false} />
      </ResponsiveContainer>
    </Page>
  );
};
