import { AppStateContext } from "appStateProvider";
import { getI8nText } from "components/common/language/language";
import { useContext } from "react";

export const useIntl = (): { msg: (key: string) => string } => {
  const appContext = useContext(AppStateContext);

  const msg = (key: string) => getI8nText(appContext.language, key);
  return { msg: msg };
};
