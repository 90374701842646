import { ResponsiveContainer } from "components/common/responsiveContainer/responsiveContainer";
import { Page } from "components/layout/page";
import { PATH__TIME_CALCULATOR } from "config/paths";
import { useIntl } from "hooks/useIntl";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

export const Utilities = (): ReactElement => {
  const { msg } = useIntl();

  return (
    <Page className="utilities">
      <ResponsiveContainer>
        <h1>{msg("page.utilities.title")}</h1>
        <div className="">
          <Link to={PATH__TIME_CALCULATOR}>{msg("page.timeCalculator.title")}</Link>
        </div>
      </ResponsiveContainer>
    </Page>
  );
};
