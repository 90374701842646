export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

export type ThemeType = {
  bp: {
    mobile: string;
  };
  bgColor1: string;
  bgColor2: string;
  bgColor3: string;
  fgColor1: string;
  fgColor2: string;
  fgColor3: string;
  fontFamilyPrimary: string;
  fontFamilySecondary: string;
  fontFamilyTab: string;
};
const THEME_CONSTANTS__BREAKPOINT = 1024;

const THEME__LIGHT = {
  bp: {
    mobile: THEME_CONSTANTS__BREAKPOINT + "px",
  }, //media query breakpoint. Usage: @media (max-width: ${props => props.theme.bp.mobile}){} @media (min-width: ${props => props.theme.bp.mobile}+1){}
  bgColor1: "#2f67b1", //Dark grey
  bgColor2: "#28a52e", //Dark grey (lighter)
  bgColor3: "#7a8b9c", //Light grey
  fgColor1: "#b81919", //White
  fgColor2: "#83407f", //Gold
  fgColor3: "lightgrey", //Light grey
  fontFamilyPrimary: "",
  fontFamilySecondary: "",
  fontFamilyTab: "",
};

const THEME__DARK = {
  bp: {
    mobile: THEME_CONSTANTS__BREAKPOINT + "px",
  }, //media query breakpoint. Usage: @media (max-width: ${props => props.theme.bp.mobile}){} @media (min-width: ${props => props.theme.bp.mobile}+1){}
  bgColor1: "#181b1f", //Dark grey
  bgColor2: "#20232a", //Dark grey (lighter)
  bgColor3: "#dedfe0", //Light grey
  fgColor1: "#ffffff", //White
  fgColor2: "#f5c02f", //Gold
  fgColor3: "lightgrey", //Light grey
  fontFamilyPrimary: "",
  fontFamilySecondary: "",
  fontFamilyTab: "",
};

export const getTheme = (theme: Theme): ThemeType => {
  switch (theme) {
    case Theme.LIGHT:
      return THEME__LIGHT;

    case Theme.DARK:
    default:
      return THEME__DARK;
  }
};
