import axios from "axios";
import { Config } from "config/config";
import { useIntl } from "hooks/useIntl";
import { isNil } from "lodash";
import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import { useHistory } from "react-router";
import { Spinner } from "../spinner";

type Props = {
  onSwitchToSignIn?: () => void;
  showAlternativeForm?: boolean;
  onSuccessRedirectTo?: string;
};

export const SignUpForm = (props: Props): ReactElement => {
  const { onSwitchToSignIn, showAlternativeForm, onSuccessRedirectTo } = props;
  const { msg } = useIntl();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usernameErrors, setUsernameErrors] = useState<Array<string>>([]);
  const [emailErrors, setEmailErrors] = useState<Array<string>>([]);
  const [passwordErrors, setPasswordErrors] = useState<Array<string>>([]);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const getErrorMessage = (errorCode: number): string => {
    if (errorCode === 0) {
      return "";
    } else {
      return Config.API_RESPONSE_MESSAGE[errorCode];
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const userSignUp = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.SIGNUP_SERVICE_URL}`,
      method: "POST",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        email: email,
        password: password,
        /*role: ["mod","user"]*/
        role: ["user"],
      },
    })
      .then(() => {
        setRequestInProgress(false);
        if (!isNil(onSuccessRedirectTo)) {
          history.push({ pathname: onSuccessRedirectTo, state: { username: username } });
        }
      })
      .catch(error => {
        setRequestInProgress(false);
        console.log("Request error response:", error.response);
        if (!isNil(error.response.data.code)) {
          switch (error.response.data.code) {
            case 1:
              setUsernameErrors([getErrorMessage(error.response.data.code)]);
              break;
            case 2:
              setEmailErrors([getErrorMessage(error.response.data.code)]);
              break;
            default:
          }
        } else {
          const usernameErrors: Array<string> = [];
          const emailErrors: Array<string> = [];
          const passwordErrors: Array<string> = [];

          if (!isNil(error.response.data.errors) && error.response.data.errors.length > 0) {
            error.response.data.errors.map((error: { field: string; defaultMessage: string }) => {
              if (error.field === "username") {
                usernameErrors.push(capitalizeFirstLetter(error.defaultMessage));
              }
              if (error.field === "email") {
                emailErrors.push(capitalizeFirstLetter(error.defaultMessage));
              }
              if (error.field === "password") {
                passwordErrors.push(capitalizeFirstLetter(error.defaultMessage));
              }
              return undefined;
            });
          }
          setUsernameErrors(usernameErrors);
          setEmailErrors(emailErrors);
          setPasswordErrors(passwordErrors);
        }
      });
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (usernameErrors.length > 0) {
      setUsernameErrors([]);
    }
    setUsername(event.target.value);
  };

  const handleEmailNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (emailErrors.length > 0) {
      setEmailErrors([]);
    }
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (passwordErrors.length > 0) {
      setPasswordErrors([]);
    }
    setPassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    userSignUp();
  };

  return (
    <div className="form-wrapper">
      <div className="header">{msg("login.signUp")}</div>

      <div className="body">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            autoComplete="username"
            id="username"
            name="username"
            placeholder={msg("login.enterUsername")}
            value={username}
            minLength={3}
            maxLength={20}
            required
            tabIndex={1}
            onChange={handleUserNameChange}
            spellCheck={false}
            data-error={usernameErrors.length > 0 ? "true" : "false"}
          />
          <div className="errorMessage">
            {usernameErrors.map((error, i) => (
              <div key={i}>{error}</div>
            ))}
          </div>

          <input
            type="email"
            autoComplete="email"
            id="email"
            name="email"
            placeholder={msg("login.enterEmail")}
            value={email}
            minLength={6}
            maxLength={40}
            required
            tabIndex={2}
            onChange={handleEmailNameChange}
            spellCheck={false}
            data-error={emailErrors.length > 0 ? "true" : "false"}
          />
          <div className="errorMessage">
            {emailErrors.map((error, i) => (
              <div key={i}>{error}</div>
            ))}
          </div>

          <input
            type="password"
            //autoComplete="password"
            id="password"
            name="password"
            placeholder={msg("login.enterPassword")}
            value={password}
            minLength={6}
            maxLength={40}
            required
            tabIndex={3}
            onChange={handlePasswordChange}
            data-error={passwordErrors.length > 0 ? "true" : "false"}
          />
          <div className="errorMessage">
            {passwordErrors.map((error, i) => (
              <div key={i}>{error}</div>
            ))}
          </div>

          {requestInProgress ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <input type="submit" value={msg("login.signUp")} tabIndex={4} />
          )}
        </form>
      </div>

      {!isNil(showAlternativeForm) && showAlternativeForm && (
        <div className="footer">
          <a className="link" tabIndex={5} onClick={onSwitchToSignIn}>
            {msg("login.alreadyAMember")}
          </a>
        </div>
      )}
    </div>
  );
};

// axios SUCESSFUL REGISTRATION RESPONSE
// {
//   "data": {
//     "message": "User registered successfully!"
//   },
//   "status": 200,
//   "statusText": "",
//   "headers": {
//     "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
//     "content-type": "application/json",
//     "expires": "0",
//     "pragma": "no-cache"
//   },
//   "config": {
//     "url": "http://localhost:8080/api/auth/signup",
//     "method": "post",
//     "data": "{\"username\":\"pablo@gmail.com\",\"email\":\"pablo@gmail.com\",\"password\":\"passw0rd\",\"role\":[\"mod\",\"user\"]}",
//     "headers": {
//       "Accept": "application/json, text/plain, */*",
//       "Content-Type": "application/json"
//     },
//     "transformRequest": [
//       null
//     ],
//     "transformResponse": [
//       null
//     ],
//     "timeout": 4000,
//     "xsrfCookieName": "XSRF-TOKEN",
//     "xsrfHeaderName": "X-XSRF-TOKEN",
//     "maxContentLength": -1
//   },
//   "request": {}
// }
