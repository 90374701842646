import React, { ReactElement } from "react";
import styled from "styled-components";
import { BackgroundShader } from "./backgroundShader";

const SideMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.fgColor2};
  margin-top: 0; /* Use this setting to move drawer vertically */
  height: 100vh;
  text-align: center;
  z-index: 20;
  box-shadow: rgb(0, 0, 0) 0 5px 20px -10px;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;

  &.visible {
    transform: translateX(0%);
  }

  .drawer {
    height: 100vh;
    overflow-y: auto;
  }

  ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    margin: 0;
  }
`;

type Props = {
  visible: boolean;
  setVisible: (val: boolean) => void;
  children: ReactElement;
};

export const Drawer = (props: Props): ReactElement => {
  const { visible, setVisible, children } = props;

  const hideDrawer = () => setVisible(false);

  return (
    <>
      <SideMenu className={visible ? "visible" : ""}>
        <div className="drawer">{children}</div>
      </SideMenu>
      <BackgroundShader opacity="0.5" className={visible ? "visible" : ""} onClick={hideDrawer} />
    </>
  );
};
