import logo from "assets/icons/logo.svg";
import { PATH__HOME } from "config/paths";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div`
  img {
    height: 2.5rem;
    display: block;
  }
`;

export const Logo = (): ReactElement => {
  return (
    <StyledDiv>
      <Link to={`${PATH__HOME}`}>
        <img alt="header-logo" src={logo} />
      </Link>
    </StyledDiv>
  );
};
