import { AppStateContext } from "appStateProvider";
import axios from "axios";
import { Config } from "config/config";
import {
  PATH__EMAIL_SENT,
  PATH__FORGOTTEN_PASSWORD,
  PATH__HOME,
  PATH__PASSWORD_RESTORED,
  PATH__RESET_PASSWORD
} from "config/paths";
import { useIntl } from "hooks/useIntl";
import { isNil } from "lodash";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useState } from "react";
import { useHistory } from "react-router";
import { Spinner } from "../spinner";

type Props = {
  onSwitchToSignUp?: () => void;
  showAlternativeForm?: boolean;
  onSuccessRedirectTo?: string;
};

export const SignInForm = (props: Props): ReactElement => {
  const { onSwitchToSignUp, showAlternativeForm, onSuccessRedirectTo } = props;

  const appContext = useContext(AppStateContext);
  const { msg } = useIntl();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signInError, setSignInError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const userSignIn = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.SIGNIN_SERVICE_URL}`,
      method: "POST",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        password: password,
      },
    })
      .then(res => {
        setRequestInProgress(false);
        appContext.setUser(res.data);
        sessionStorage.setItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN, res.data.tokenType + " " + res.data.accessToken);
        sessionStorage.setItem(Config.LOCALSTORAGE_USER__JWT_DATA, JSON.stringify(res.data));

        if (!isNil(onSuccessRedirectTo)) {
          history.push(onSuccessRedirectTo);
        } else {
          const redirectPages = [
            PATH__EMAIL_SENT,
            PATH__FORGOTTEN_PASSWORD,
            PATH__PASSWORD_RESTORED,
            PATH__RESET_PASSWORD,
          ];
          if (redirectPages.includes(document.location.pathname)) {
            history.push(PATH__HOME);
          }
        }
      })
      .catch(error => {
        console.log("Request error response:", error.response);
        setSignInError(true);
        setRequestInProgress(false);
      });
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (signInError) {
      setSignInError(false);
    }
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (signInError) {
      setSignInError(false);
    }
    setPassword(event.target.value);
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!requestInProgress) {
      userSignIn();
    }
  };

  const getErrorMessage = () => {
    if (signInError) {
      return <div className="errorMessage">{msg("login.loginFailed")}</div>;
    } else {
      return "";
    }
  };

  return (
    <div className="form-wrapper">
      <div className="header">{msg("login.signIn")}</div>

      <div className="body">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            autoComplete="username"
            id="username"
            name="username"
            placeholder={msg("login.enterUsername")}
            value={username}
            tabIndex={1}
            onChange={handleUserNameChange}
            spellCheck={false}
            minLength={3}
            maxLength={20}
            required
            data-error={signInError === true ? "true" : "false"}
          />
          <br />
          <input
            type="password"
            autoComplete="password"
            id="password"
            name="password"
            placeholder={msg("login.enterPassword")}
            value={password}
            minLength={6}
            maxLength={40}
            required
            tabIndex={2}
            onChange={handlePasswordChange}
            data-error={signInError === true ? "true" : "false"}
          />
          <br />

          {requestInProgress ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <input type="submit" value={msg("login.signIn")} tabIndex={3} />
          )}
          <br />
          {getErrorMessage()}
          {/* <a tabIndex={4} href="/api/user/reset-password/email@email.com">{msg("login.forgotPassword")}</a> */}
          <a href={`${PATH__FORGOTTEN_PASSWORD}?v=${Config.APP_VERSION}`}>{msg("login.forgotPassword")}</a>
        </form>
      </div>

      {!isNil(showAlternativeForm) && showAlternativeForm && (
        <div className="footer">
          <a className="link" tabIndex={5} onClick={onSwitchToSignUp}>
            {msg("login.notAMember")}
          </a>
        </div>
      )}
    </div>
  );
};

/*
fetch SUCCESSFUL RESPONSE:
{
  "id": 2,
  "username": "pepe@gmail.com",
  "email": "pepe@gmail.com",
  "roles": [
    "ROLE_MODERATOR",
    "ROLE_USER"
  ],
  "tokenType": "Bearer",
  "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlQGdtYWlsLmNvbSIsImlhdCI6MTU4NzQ4NTMwNiwiZXhwIjoxNTg3NTcxNzA2fQ.QPGHcVJYg4Tzjp9FA6obXDJkvBct3W06nJvLUKPq1nZAq17kHFGSnstYU2BN5P-DluNqDAXPh30f8S28ZYw7gQ"
}

fetch ERROR RESPONSE:
{
  "timestamp": "2020-04-21T16:07:52.683+0000",
  "status": 401,
  "error": "Unauthorized",
  "message": "Error: Unauthorized",
  "path": "/api/auth/signin"
}
*/

// axios SUCCESSFUL RESPONSE
// {
//   "data": {
//     "id": 2,
//     "username": "pepe@gmail.com",
//     "email": "pepe@gmail.com",
//     "roles": [
//       "ROLE_USER",
//       "ROLE_MODERATOR"
//     ],
//     "tokenType": "Bearer",
//     "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlQGdtYWlsLmNvbSIsImlhdCI6MTU4NzU1MzQ4MSwiZXhwIjoxNTg3NjM5ODgxfQ.XkM7hHGJSXJajCY_1hNLlaz_ALVRNa8JUopWyrsOlFXX6Q1NnWAJeaOuQN-5HUwkZ8MHCG_dI9rEQ0fg3XbJPg"
//   },
//   "status": 200,
//   "statusText": "",
//   "headers": {
//     "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
//     "content-type": "application/json",
//     "expires": "0",
//     "pragma": "no-cache"
//   },
//   "config": {
//     "url": "http://localhost:8080/api/auth/signin",
//     "method": "post",
//     "data": "{\"username\":\"pepe@gmail.com\",\"password\":\"passw0rd\"}",
//     "headers": {
//       "Accept": "application/json, text/plain, */*",
//       "Content-Type": "application/json;charset=utf-8"
//     },
//     "transformRequest": [
//       null
//     ],
//     "transformResponse": [
//       null
//     ],
//     "timeout": 4000,
//     "xsrfCookieName": "XSRF-TOKEN",
//     "xsrfHeaderName": "X-XSRF-TOKEN",
//     "maxContentLength": -1
//   },
//   "request": {}
// }
