export enum Environment {
  DEVELOPMENT = "dev",
  PRODUCTION = "prod",
}

export enum Role {
  USER = "ROLE_USER",
  MODERATOR = "ROLE_MODERATOR",
  ADMIN = "ROLE_ADMIN",
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
}

export type HttpHeaders = {
  "Content-Type"?: string;
  Authorization?: string;
};

export type HttpRequest = {
  url: string;
  method: HttpMethod;
  timeout: number;
  headers: HttpHeaders;
};

export type User = {
  id: number;
  username: string;
  email: string;
  roles: Array<string>;
  accessToken: string;
  tokenType: string;
};

