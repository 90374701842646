import React, { ReactElement, useState } from "react";
import { NavigationBarItemMenu } from "./navigationBarItemMenu/navigationBarItemMenu";

type Props = {
  item: ReactElement;
  menu: ReactElement;
};

export const NavigationBarItemWithMenu = (props: Props): ReactElement => {
  const { item, menu } = props;

  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <div className="navigation-bar-item-with-menu">
      <div className="item" onClick={() => setMenuVisible(!menuVisible)}>
        {item}
      </div>

      <div className="item">
        <NavigationBarItemMenu visible={menuVisible} setVisible={setMenuVisible}>
          {menu}
        </NavigationBarItemMenu>
      </div>
    </div>
  );
};
