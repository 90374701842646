import { AppStateContext } from "appStateProvider";
import { Config } from "config/config";
import { Theme } from "config/theme";
import { useIntl } from "hooks/useIntl";
import React, { ChangeEvent, ReactElement, useContext, useState } from "react";
import "./themeSelector.scss";

export const ThemeSelector = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const { msg } = useIntl();

  const [selectedOption, setSelectedOption] = useState(appContext.theme);

  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    window.localStorage.setItem(Config.LOCALSTORAGE_APP__THEME, e.target.value);
    const selectedTheme = e.target.value as Theme;
    setSelectedOption(selectedTheme);
    appContext.setTheme(selectedTheme);
  };

  return (
    <div className="theme-selector">
      <label>{msg("global.theme")}</label>
      <select className="select-css" value={selectedOption} onChange={onChangeHandler}>
        {Object.entries(Theme).map(el => (
          <option key={el[0]} value={el[1]}>
            {msg("theme."+el[1])}
          </option>
        ))}
      </select>
    </div>
  );
};
